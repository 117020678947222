import React, { Component } from 'react';

class Contact extends Component {
  render() {

    if(this.props.data){
      var emailHref = `mailto:${this.props.data.email}`;
      var message = this.props.data.contactmessage;
    }

    return (
      <section id="contact" className="text-center">
        <div className="row section-head d-flex justify-content-center">
          <div className="eight columns header-col"><h1><span>Get In Touch</span></h1></div>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="eight columns"><p className="lead">{message}</p></div>
        </div>

        <a href={emailHref} target="_blank" rel="nofollow noopener noreferrer" className="button mt-3">Say Hello</a>
      </section>
    );
  }
}

export default Contact;
